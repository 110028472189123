import anime from 'animejs';
import {useState,useRef,useEffect} from 'react';
import React from 'react';

export default function Anim() {
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        setIsLoaded(true);
    }, []);
    return (
        <>
        {isLoaded? <Anim1/> : <Anim2/>}
</>
        
    )
}

function Anim2(){
    return(
        <div>
            <p className="text-3xl font-mono text-transparent">Hi! I'm</p>
            <p className="text-5xl sm:text-6xl font-bold tracking-wide main text-slate-200 opacity-45">mersnn621</p>
        </div>
    )
}

function Anim1(){
    return(
        <div ref={
            (el) => {
                if (el) {
                    anime({
                        targets: el,
                        translateX: ["-100vw", 0],
                        opacity: [0, 1],
                        duration: 500,
                        easing: 'easeOutElastic',
                    });
            }
        }}>
            <p className="text-3xl font-mono text-slate-200">Hi! I'm</p>
            <p className="text-5xl sm:text-6xl font-bold tracking-wide main text-slate-200">
                {"mersnn621".split('').map((letter, i) => (
                    
                    <span key={letter} className="inline-block" ref={
                        (el) => {
                            if (el) {
                                anime({
                                    targets: el,
                                    translateY: [50, 0],
                                    filter: ['blur(10px)', 'blur(0px)'],
                                    opacity: [0, 1],
                                    duration: 700,
                                    easing: 'easeOutElastic',
                                    delay: 50 * i+250,
                                });
                            }
                        }
                    } onMouseEnter={
                        (e) => {
                            anime({
                                targets: e.currentTarget,
                                translateY: [-10,0],
                                scale: [1.2,1],
                                opacity: [0,1],
                                duration: 300,
                                easing: 'easeOutElastic',
                                delay: 100,
                            });
                        }
                    }>{letter}</span>
                ))}
            </p>
        </div>
    )
}